
.fade-text {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 5000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes fadein {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  .landing-section-box {
    margin-top: 55px;
    margin-bottom: 55px;
  }


.landing-section-bio {
    display: inline-block;
    min-height: 2.4em;
}

/** SMALL SCREEN */
@media only screen and (max-width: 500px) {
  .landing-section-bio {
    min-height: 8em;
  }
}

.grid {
  display: flex;
  max-width: 1024px;
}

.item {
  flex-basis: 50%;
  justify-content: center;
  margin: 0 auto;
}


.profile-pic {
  border-radius: 100%;
  max-height: 8em;
  max-width: 8em;
}

