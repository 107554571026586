#project-template {
    display: grid;
    grid-template-columns: "2fr 1fr 1fr";
    grid-template-rows: "1fr 1fr 3fr";
    grid-template-areas:
        "summary summary links"
        "summary summary techs"
        "image image image";
    max-width: 1024px;

    row-gap: 1em;
}

#project-title {
    font-size: 4vw;
}
#project-subtitle {
    font-size: 2vw;
    padding-bottom: 0.3em;

}
#summary {
    grid-area: summary;
    max-width: 700px;
}

#techs {
    grid-area: techs;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
    font-weight: bold;
    justify-content: flex-end;
}

#links {
    grid-area: links;
    display: flex;
    column-gap: 1.5em;
    gap: 1em;
    flex-flow: row wrap;
    justify-content: flex-end;
}

#image {
    grid-area: image;
}


#project-vid {
    border-radius: 0.8em;
}

@media only screen and (max-width: 550px) {
    #project-template {
        display: grid;
        grid-template-columns: "2fr 1fr";
        grid-template-rows: "1fr 1fr 3fr";
        grid-template-areas:
            "summary summary"
            "techs links"
            "image image";
        column-gap: 1em;
        row-gap: 1em;
    }

    #techs {
        display: block;
        text-align: left;
    }
    #links {
        justify-content: flex-end;
    }

    #project-title {
        font-size: 2em;
    }

    #project-subtitle {
        font-size: 1.5em;
    }
}
