.skill-card {
    background-color: rgb(235, 234, 234);
    border-radius: 1em;
    color: white;
    flex: 1;
    min-width: 15em;
}

.skill-card-body {
    color: black;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 1em;
    min-width: 180px;
}

.skill-card-icon-box {
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 1em 0;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

.skills-section-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding-top: 1em;
    gap: 2rem 1rem;
}

#skills-section-header {
    font-size: 10vw;
}

#skills-section {
    display: grid;
}

