#about-section-heading {
    font-size: 10vw;

}

#bio-name {
    font-size: clamp(30px, 4vw, 200px);
    font-weight: bold;
}

#bio {
    grid-area: bio;
    max-width: 450px;
}

#image-box {
    grid-area: image-box;
    max-width: 360px;
    max-height: 330px;
    display: grid;
    justify-items: center;
    align-items: center;


    margin: 0 auto;
}

#contact {
    grid-area: contact;
}


#more-bio {
    grid-area: more-bio;
    max-width: 450px;
}


#about-section-body {
    display: grid;
    grid-template-columns: "1fr 1fr";
    grid-template-rows: "1fr 0.8fr 1fr 1fr 1fr";
    max-width: 1024px;
    column-gap: 4em;
    row-gap: 2em;
    grid-template-areas: 
        "bio image-box"
        "bio contact"
        "more-bio hobbies"
        "more-bio hobbies"
        "more-bio hobbies";

}

#about-section-body p, #hobbies {
    line-height: 1.6em;
}



#hobbies ul {
    padding-left: 1.4em;

}

#image-box {
    display: flex;
    justify-content: center;
    margin: 0 auto;

}

#image-box img {
    border-radius: 0.7em;
}

#contact {
    display: flex;
    justify-content: space-evenly;
}

#hobbies {
    grid-area: hobbies;
}

#can-do {
    border-top: grey solid 0.15em;
    border-bottom: grey solid 0.15em;
    padding: 0.8em 0.6em;
}

#about-section-body b {
    font-size: 1.15em;
}

 #hobbies p {
padding-bottom: 0.6em;
}

@media only screen and (max-width: 760px) {
    #about-section-body {
        display: grid;
        grid-template-columns: "1fr 1fr 1fr 1fr 1fr";
        grid-template-rows: "1fr 1fr 1fr 1fr 1fr";
        column-gap: 0em;
        grid-template-areas:
          "bio bio bio bio bio"
          "contact image-box image-box image-box image-box"
          "contact image-box image-box image-box image-box"
          "more-bio more-bio more-bio more-bio more-bio"
          "hobbies hobbies hobbies hobbies hobbies";
    }

    #contact {
        display: grid;
        justify-content: left;
    }
}