.header-section a {
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    font-weight: bold;
}

.nav-item:hover {
    background-color: #ddd;
    color: black;
    cursor: pointer;
}

.without-hamburger-box, .with-hamburger-socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.without-hamburger-box {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}

.header-section-box {
    color: white;
    max-width: 1000px;
    display: block;
    justify-content: space-evenly;
    margin: 0 auto;
    font-size: 1em;
    padding: 0.5em 1em;
}

#with-hamburger-box > * {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

.with-hamburger-nav {
    display: block;
    text-align: right;
}

.with-hamburger-nav:hover {
    background-color: #ddd;
    color: black;
    border-radius: 0.3em;
}

#with-hamburger-box {
    display: none;
}


@media only screen and (max-width: 650px) {
    .hamburger {
        display: inline-block;
      }
      /* hide all the nav-buttons */
    .without-hamburger {
        display: none;
    }
}

/** WIDE SCREEN */
@media only screen and (min-width: 651px) {
    .hamburger {
        display: none;
      }
      /* hide all the nav-buttons */
    .without-hamburger {
        display: inline-block;
    }

    #with-hamburger-box {
        display: none;
    }
}